export const GET_REDEEM = "GET_REDEEM";

export const CREATE_NEW_REDEEM = "CREATE_NEW_REDEEM";
export const SET_CREATE_REDEEM_DONE = "SET_CREATE_REDEEM_DONE";
export const UNSET_CREATE_REDEEM_DONE = "UNSET_CREATE_REDEEM_DONE";

export const OPEN_REDEEM_DIALOG = "OPEN_REDEEM_DIALOG";
export const CLOSE_REDEEM_DIALOG = "CLOSE_REDEEM_DIALOG";

export const EDIT_REDEEM = "EDIT_REDEEM";
export const SET_UPDATE_REDEEM_DONE = "SET_UPDATE_REDEEM_DONE";
export const UNSET_UPDATE_REDEEM_DONE = "UNSET_UPDATE_REDEEM_DONE";

export const GET_AGENCY_COIN_DETAIL = "GET_AGENCY_COIN_DETAIL";

export const DELETE_REDEEM = "DELETE_REDEEM";
