import { useEffect, useState, Fragment } from "react";

import { Link, useLocation } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { getHostWeekWiseSettlement } from "../../store/host/action";
import dayjs from "dayjs";
import { useHistory } from "react-router-dom";
import TablePaginationActions from "./TablePagination";
import { TablePagination } from "@material-ui/core";

const HostweekWiseSettlement = (props) => {
  const { id } = useSelector((state) => state.admin.user);
  const { hostWeekWiseSettlement } = useSelector((state) => state.host);
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([]);

  useEffect(() => {
    props.getHostWeekWiseSettlement(id);
  }, []);


  useEffect(() => {
    setData(hostWeekWiseSettlement);
  }, [hostWeekWiseSettlement]);



  
  //Handle Open Settlement Report Table
  const handleOpenAgencySettlement = (id) => {
    history.push({
      pathname: "/admin/hostIncomeReport/hostSettlementReportHistory",
      state: id,
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Fragment>
        <div class="page-breadcrumb">
          <div class="row">
            <div class="col-7 align-self-center">
              <div class="d-flex align-items-center">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb m-0 p-0">
                    <li class="breadcrumb-item">
                      <Link to="/admin/dashboard" class="text-muted">
                        Home
                      </Link>
                    </li>
                    <li class="breadcrumb-item">
                      <Link to="/admin/host/allHost" class="text-muted">
                        Host
                      </Link>
                    </li>
                    <li
                      class="breadcrumb-item text-muted active"
                      aria-current="page"
                    >
                      Host Settlement week wise  History
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>


                  
      <div class="container-fluid">
        <div class="row">
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-4 float-left">
                    <div className="row">
                      
                      <div className="col-xs-12 col-sm-12 col-md-6 col-lg-4 mt-xs-3 mt-2">
                        {/* <DateRangePicker
                          initialSettings={{
                            autoUpdateInput: false,
                            locale: {
                              cancelLabel: "Clear",
                            },
                            maxDate: new Date(),

                            buttonClasses: ["btn btn-dark"],
                          }}
                          onApply={handleApply}
                          onCancel={handleCancel}
                        >
                          <input
                            type="text"
                            class="daterange form-control float-left "
                            placeholder="Select Date"
                            style={{ width: 185, fontWeight: 700 }}
                          />
                        </DateRangePicker> */}
                      </div>
                    </div>
                    {/* <h3 class="card-title">Host</h3> */}
                  </div>
                  
                </div>
                <div class="row mt-2">
                  <div class="col-xs-12 col-sm-12 col-md-6 col-lg-8 mt-3 float-left mb-0"></div>
                </div>

                <div class="table-responsive">
                  <table
                    id="zero_config"
                    class="table table-striped table-bordered no-wrap"
                  >
                    <thead>
                    <tr>
                         <th>Settlement Date</th>
                         <th>Settlement Cycle</th>
                         <th>My Commission</th>
                         <th>Total cash out Amount</th>
                         <th>Host Info</th>
                    
                        </tr>
                    </thead>
                    <tbody>
                        {data?.length > 0 ? (
                          <Fragment>
                            {(rowsPerPage > 0
                              ? data?.slice(
                                  page * rowsPerPage,
                                  page * rowsPerPage + rowsPerPage
                                )
                              : data
                            ).map((data, index) => {
                                var startDate = data?.startDate?.split(",") || []
                                var endDate = data?.endDate?.split(",") || []

                              return (
                                <tr key={index}>
                                 <td style={{verticalAlign:"middle"}}>{dayjs(startDate[0]).format("DD MMM, YYYY")}</td>
                                 <td style={{verticalAlign:"middle"}}>{dayjs(startDate[0]).format("DD MMM, YYYY")} To {dayjs(endDate[0]).format("DD MMM, YYYY")}</td>
                                 <td style={{verticalAlign:"middle"}}>{data?.agencySalary}</td>
                                 <td style={{verticalAlign:"middle"}}>{data?.totalAgencySalary}</td>
                             <td>
                                  <button
                                    type="button"
                                    class="btn btn-fill btn-primary btn-sm"
                                    style={{
                                      borderRadius: 5,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleOpenAgencySettlement(data)
                                    }
                                  >
                                    Host Details
                                  </button>
                                </td>
                                </tr>
                              );
                            })}
                          </Fragment>
                        ) : (
                          <tr>
                            <td colSpan="10" align="center">
                              Nothing to show!!
                            </td>
                          </tr>
                        )}
                      </tbody>
                    <tfoot>
                    <tr>
                         <th>Settlement Date</th>
                         <th>Settlement Cycle</th>
                         <th>My Commission</th>
                         <th>Total cash out Amount</th>
                         <th>Host Info</th>
                    
                        </tr>
                    </tfoot>
                  </table>
                </div>
                <div class="py-2">
                  <TablePagination
                    id="pagination"
                    component="div"
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      100,
                      { label: "All", value: -1 },
                    ]}
                    count={data?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: { "aria-label": "rows per page" },
                      native: true,
                    }}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </Fragment>
    </>
  );
};

export default connect(null, { getHostWeekWiseSettlement })(
  HostweekWiseSettlement
);
