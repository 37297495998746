export const GET_ACCEPTED_REDEEM = "GET_ACCEPTED_REDEEM";
export const GET_PENDING_REDEEM = "GET_PENDING_REDEEM";

export const ACCEPT_REDEEM_REQUEST = "ACCEPT_REDEEM_REQUEST";

export const DECLINE_REDEEM = "DECLINE_REDEEM";

export const OPEN_REDEEM_ACCEPT_DIALOG = "OPEN_REDEEM_ACCEPT_DIALOG";
export const CLOSE_REDEEM_ACCEPT_DIALOG = "CLOSE_REDEEM_ACCEPT_DIALOG";

export const ACCEPT_SUCCESS = "ACCEPT_SUCCESS";
export const CLOSE_ACCEPT_SUCCESS = "CLOSE_ACCEPT_SUCCESS";
